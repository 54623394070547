import PropTypes from "prop-types";

const PigPlusFilled = ({ color }) => (
    <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.9886 10.85C21.9586 10.35 21.7286 9.88 21.3586 9.54C20.9886 9.19 20.4986 9 19.9986 9H19.3186L19.2286 8.81C18.7086 7.8 17.9586 6.93 17.0286 6.28L16.9986 6.26V3C16.9986 2.87 16.9786 2.74 16.9186 2.62C16.8686 2.5 16.7986 2.39 16.7086 2.3C16.6086 2.2 16.4986 2.13 16.3786 2.08C16.2586 2.03 16.1286 2 15.9986 2H15.8786C15.7286 2.03 15.5786 2.08 15.4486 2.17L11.1986 5H8.99865C7.57865 5 6.19865 5.43 5.02865 6.24C3.85865 7.04 2.96865 8.18 2.45865 9.51L2.36865 9.76C1.95865 10.97 1.88865 12.27 2.16865 13.52C2.43865 14.77 3.05865 15.92 3.94865 16.85L3.99865 16.9V18.5C3.99865 19.17 4.25865 19.8 4.72865 20.27C5.19865 20.74 5.83865 21 6.49865 21H6.66865C7.18865 20.96 7.68865 20.77 8.08865 20.43C8.49865 20.1 8.78865 19.65 8.91865 19.14L8.94865 19H13.0486L13.0786 19.14C13.3686 20.21 14.3386 21 15.4986 21H15.6586C16.2986 20.96 16.8886 20.68 17.3186 20.21C17.7586 19.75 17.9986 19.14 17.9986 18.5V16.9L18.1186 16.77C18.5586 16.31 18.9286 15.78 19.2186 15.22L19.3186 15H19.9986C20.5286 15 21.0386 14.79 21.4086 14.42C21.7786 14.05 21.9986 13.53 21.9986 13V10.85H21.9886ZM12.0186 9.37H8.26865C7.91865 9.37 7.63865 9.09 7.63865 8.74C7.63865 8.39 7.91865 8.12 8.26865 8.12H12.0186C12.3586 8.12 12.6386 8.4 12.6386 8.74C12.6386 9.08 12.3586 9.37 12.0186 9.37ZM15.9886 11.13C15.9586 11.38 15.8386 11.62 15.6386 11.78C15.4486 11.94 15.1986 12.03 14.9386 12.01C14.6886 12.01 14.4486 11.88 14.2686 11.7C14.0986 11.51 13.9986 11.27 13.9986 11.01V10.89C14.0386 10.64 14.1486 10.42 14.3386 10.26C14.5186 10.09 14.7586 10 14.9986 10C15.2686 10 15.5186 10.11 15.7086 10.3C15.8986 10.48 15.9986 10.74 15.9986 11V11.13H15.9886Z" fill={color}/>
        <path d="M25.62 21.7H23.24V19.32C23.24 19.32 23.22 19.24 23.21 19.2V19.15C23.21 19.15 23.2 19.13 23.19 19.11C23.19 19.08 23.17 19.06 23.16 19.04C23.14 19 23.11 18.96 23.08 18.92C23.06 18.9 23.05 18.88 23.03 18.86C22.99 18.83 22.95 18.8 22.9 18.78C22.88 18.77 22.86 18.76 22.84 18.75C22.77 18.72 22.7 18.7 22.62 18.7C22.27 18.7 22 18.98 22 19.32V21.7H19.62C19.27 21.7 19 21.98 19 22.32C19 22.49 19.07 22.65 19.18 22.76C19.22 22.8 19.26 22.82 19.31 22.85C19.33 22.86 19.35 22.89 19.38 22.9C19.45 22.93 19.54 22.95 19.62 22.95H22V25.33C22 25.68 22.28 25.95 22.62 25.95C22.96 25.95 23.24 25.67 23.24 25.33V22.95H25.62C25.97 22.95 26.24 22.67 26.24 22.33C26.24 21.99 25.96 21.71 25.62 21.71V21.7Z" fill={color}/>
    </svg>
);

PigPlusFilled.propTypes = {
    color: PropTypes.string,
};

PigPlusFilled.defaultProps = {
    color: "white",
};

export default PigPlusFilled
