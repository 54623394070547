import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import {useContext, useEffect, useState} from 'react';
import {UserContext} from '../../context/UserContext';
import {Nav as BootstrapNav} from 'react-bootstrap';
import {NavLink as RouterLink, useNavigate} from 'react-router-dom';
import {PERMISSIONS, ROUTES} from '../../constants';
import Styled from 'styled-components';
import packageJson from '../../../package.json';
import {getVersion} from '../../utils/API/system';
import {ShortcutHelp} from '../../hooks/useKeyPress';
import {keycloak} from '../../keycloak';
import Icon from "../Icon";
import Airplane from "../Icons/Airplane";
import AirplaneFilled from "../Icons/AirplaneFilled";
import Pig from "../Icons/Pig";
import PigPlusFilled from "../Icons/PigPlusFilled";
import TargetAdd from "../Icons/TargetAdd";
import SchoolPlusFilled from "../Icons/SchoolPlusFilled";

const BUTTON_WIDTH = 32;

const iconsColor = "#CCE6FF";

const Divider = Styled.hr`
  background-color: #0085FF;
  margin: 0px;
`;

const btnStyle = `
  color: #fff;
  width: ${BUTTON_WIDTH}px;
  height: ${BUTTON_WIDTH}px;
  padding:0;
  width: 100%;
  &:hover, &.active { color: #fff!important; }
`;
const SimpleButton = Styled.a`${btnStyle}`;

const RouterLinkButton = Styled(RouterLink)`${btnStyle}`;
const LinkButton = (props) => (
  <BootstrapNav.Link
    className="btn btn-icon btn-lg"
    as={RouterLinkButton}
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
  />
);

const Navbar = () => {
  const {
    user,
    hasClaim
  } = useContext(UserContext);
  const {version} = packageJson;
  const [beVersion, setBeVersion] = useState('');

  useEffect(() => {
    getVersion().then(setBeVersion);
  }, []);

  useEffect(() => {
    if (user.company) {
      const customization = JSON.parse(user.company.customization);
      document.documentElement.style.setProperty('--brand-dark', customization.variables.root.brandColorDark);
      document.documentElement.style.setProperty('--brand', customization.variables.root.brandColor);
      document.documentElement.style.setProperty('--brand-medium', customization.variables.root.brandColorMedium);
      document.documentElement.style.setProperty('--brand-light', customization.variables.root.brandColorLight);
      document.documentElement.style.setProperty('--brand-extra-light', customization.variables.root.brandColorExtraLight);
    }
  }, [user]);

  const navigate = useNavigate();

  return (
    <>
      {user.company && (
        <div className="navbar__wrapper">
          <BootstrapNav>
            <OverlayTrigger
              trigger={['hover', 'focus']}
              placement="right"
              overlay={
                <Popover className="popover-body">
                  <ShortcutHelp/>
                </Popover>
              }
            >
              <div className="navbar__logo">
                <img
                  src={`data:image/svg+xml;base64,${user.company.logo}`}
                  alt='logo'
                  width='35px'
                />
              </div>
            </OverlayTrigger>
            <Divider/>
            {user.is_employee && (
              <>
                <LinkButton
                  to={ROUTES.TRIPS}
                  title="Nota spese e rimborso chilometrico"
                >
                  <Icon name="tabler:stack-2" color={iconsColor} size="24px"/>
                </LinkButton>

                <LinkButton to={ROUTES.WORKLOG} title="Log ore">
                  <Icon name="tabler:clock" color={iconsColor} size="24px"/>
                </LinkButton>

                <LinkButton to={ROUTES.ABSENCES} title="Assenze">
                  <Airplane color={iconsColor}/>
                </LinkButton>

                {hasClaim(PERMISSIONS.VIEW_ABSENCES) && (
                    <LinkButton to={ROUTES.VIEW_ABSENCES} title="Visualizza assenze">
                      <AirplaneFilled color={iconsColor}/>
                    </LinkButton>
                )}

                <LinkButton to={ROUTES.BUDGET} title="Budget">
                  <Pig color={iconsColor}/>
                </LinkButton>


                {hasClaim(PERMISSIONS.BUDGET_ADMINISTRATION) && (
                    <LinkButton to={ROUTES.ADMIN_BUDGET} title="Gestione budget">
                      <PigPlusFilled color={iconsColor}/>
                    </LinkButton>
                )}

                <LinkButton to={ROUTES.RETROSPECTIVE_GOALS} title="Obiettivi" end>
                  <Icon className="navbar-icons" name="tabler:target-arrow" color={iconsColor} size="24px"/>
                </LinkButton>

                {hasClaim(PERMISSIONS.RETROSPECTIVE_ADMINISTRATION) && (
                    <LinkButton to={ROUTES.RETROSPECTIVE} title="Retrospettive" end>
                      <TargetAdd color={iconsColor}/>
                    </LinkButton>
                )}

                {hasClaim(PERMISSIONS.RETROSPECTIVE_ADMINISTRATION) && (
                    <LinkButton to={ROUTES.RETROSPECTIVE_GOAL_TYPES} title="Tipi di obiettivi" end>
                      <Icon name="tabler:archery-arrow" color={iconsColor} size="24px"/>
                    </LinkButton>
                )}

                {hasClaim(PERMISSIONS.VIEW_EARNED_CERTIFICATIONS) && (
                    <LinkButton to={ROUTES.EARNED_CERTIFICATIONS} title="Certificazioni ottenute" end>
                      <Icon name="tabler:school" color={iconsColor} size="24px"/>
                    </LinkButton>
                )}

                {hasClaim(PERMISSIONS.CERTIFICATION_ADMINISTRATION) && (
                    <LinkButton to={ROUTES.CERTIFICATIONS} title="Certificazioni" end>
                      <SchoolPlusFilled color={iconsColor}/>
                    </LinkButton>
                )}

                {hasClaim(PERMISSIONS.PROJECT_ADMINISTRATION) && (
                        <LinkButton to={ROUTES.PROJECTS} title="Progetti" end>
                          <Icon name="tabler:briefcase-2" color={iconsColor} size="24px"/>
                        </LinkButton>
                )}

                {hasClaim(PERMISSIONS.DATA_ANALYSIS) && (
                    <LinkButton to={ROUTES.REPORT} title="Report">
                      <Icon name="tabler:file-spreadsheet" color={iconsColor} size="24px"/>
                    </LinkButton>
                )}

                {hasClaim(PERMISSIONS.ADMINISTRATION) && (
                    <LinkButton to={ROUTES.ADMINISTRATION} title="Amministrazione" end>
                      <Icon name="tabler:user-screen" color={iconsColor} size="24px"/>
                    </LinkButton>
                )}

              </>
            )}


            <div className="spacer"/>

            <LinkButton to={ROUTES.PROFILE} title="Il tuo profilo">
              <Icon name="tabler:user" size="24px"/>
            </LinkButton>

            <SimpleButton
              title="Logout"
              className="btn btn-icon btn-exit btn-lg"
              onClick={() => {
                keycloak.logout();
                navigate('/');
              }}
            >
              <Icon name="tabler:logout" size="24px"/>
            </SimpleButton>
            <div className="version__txt">
              FE {version}
              <br/>
              BE {beVersion?.version ?? '?'}
            </div>
          </BootstrapNav>
        </div>
      )}
    </>
  );
};

export default Navbar;
