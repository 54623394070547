import PropTypes from "prop-types";

const Pig = ({ color }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 11V11.01M16 3V6.803C17.2376 7.51912 18.1799 8.65245 18.658 10H19.999C20.2642 10 20.5186 10.1054 20.7061 10.2929C20.8937 10.4804 20.999 10.7348 20.999 11V13C20.999 13.2652 20.8937 13.5196 20.7061 13.7071C20.5186 13.8946 20.2642 14 19.999 14H18.657C18.3206 14.9504 17.7504 15.8008 16.999 16.473V18.5C16.999 18.8978 16.841 19.2794 16.5597 19.5607C16.2784 19.842 15.8968 20 15.499 20C15.1012 20 14.7197 19.842 14.4384 19.5607C14.157 19.2794 13.999 18.8978 13.999 18.5V17.917C13.6686 17.9724 13.3341 18.0001 12.999 18H8.99901C8.66396 18.0001 8.32947 17.9724 7.99901 17.917V18.5C7.99901 18.8978 7.84098 19.2794 7.55967 19.5607C7.27837 19.842 6.89684 20 6.49901 20C6.10119 20 5.71966 19.842 5.43835 19.5607C5.15705 19.2794 4.99901 18.8978 4.99901 18.5V16.473C4.09298 15.663 3.45429 14.5969 3.1675 13.4159C2.8807 12.2348 2.95933 10.9946 3.39296 9.85922C3.82659 8.72387 4.59478 7.74697 5.59585 7.05783C6.59692 6.36869 7.78367 5.9998 8.99901 6H11.499L15.999 3H16Z" stroke={color} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
        <rect x="8" y="8" width="5" height="1.25" rx="0.625" fill={color}/>
    </svg>
);

Pig.propTypes = {
    color: PropTypes.string,
};

Pig.defaultProps = {
    color: "white",
};

export default Pig;