import _ from 'lodash';

export const getFormData = (pValues) => {
  const formData = new FormData();

  _.each(pValues, (val, key) => {
    if (key === 'attachment' || key === 'badge') {
      const file = _.filter(val, { deleted: false });
      if (file.length > 0 && file[0].fileData.path) {
        formData.append(key, file[0].fileData);
      }
    } else if (key === 'users' && Array.isArray(val)) {
      val.forEach(userId => {
        formData.append(key, userId);
      });
    } else {
      formData.append(key, val);
    }
  });

  return formData;
};