import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Col } from 'react-bootstrap';
import {
  EXPENSE_BONUS_BOOKS,
  EXPENSE_BONUS_SMART_WORKING, EXPENSE_BOOKS_BUDGET, EXPENSE_SMART_WORKING,
  PERMISSIONS,
} from '../../constants';
import { TrainingDaysDataGrid } from './TrainingDaysDataGrid';
import { UserContext } from '../../context/UserContext';
import { Unauthorized } from '../../components/Unauthorized';
import { useContext, useEffect, useMemo, useState } from 'react';
import { TrainingExpensesDataGrid } from './TrainingExpensesDataGrid';
import { TabWithChart } from './TabWithChart';
import { getExpensesByTypeAndYear } from '../../utils/API/expenses';
import _ from 'lodash';
import { getTrainingDays, getTrainingExpenses } from '../../utils/API/budgets';
import { MonoSelectUser } from '../../components/Form/MonoSelectUser';
import { UserListContext } from '../../context/UserListContext';
import { BudgetContext } from '../../context/BudgetContext';
import { renderSpinner } from '../../utils/Utility';
import { useTranslation } from 'react-i18next';
import { BonusExpensesDataGrid } from './BonusExpensesDataGrid';

const getExpenses = async (type, year, username) => {
  const expensesData = await getExpensesByTypeAndYear(type, year, username);
  return _.orderBy(expensesData, ['date'], ['asc']);
};

const getClass = (available, max) =>
  `percent-${Math.max(0, Math.floor((100 * available) / max))}`;

export const BudgetPage = () => {
  const { t } = useTranslation();
  const { user, hasClaim } = useContext(UserContext);
  const { BUDGET_ADMINISTRATION } = PERMISSIONS;

  const canSeeOtherUsers = hasClaim(BUDGET_ADMINISTRATION);
  const isForbidden = !user.is_employee && !canSeeOtherUsers;
  const { users, usersById } = useContext(UserListContext);
  const [impersonatedUserId, setImpersonatedUserId] = useState(null);
  const username = useMemo(
    () => usersById[impersonatedUserId]?.username,
    [impersonatedUserId, usersById]
  );

  const { year, setYear, configuration, availableYears } = useContext(BudgetContext);
  
  const [booksBudget, setBooksBudget] = useState(0);
  const [smartWorkingBudget, setSmartWorkingBudget] = useState(0);

  const [bookExpenses, setBookExpenses] = useState([]);
  const [smartExpenses, setSmartExpenses] = useState([]);
  const [bookExpensesNew, setBookExpensesNew] = useState([]);
  const [smartExpensesNew, setSmartExpensesNew] = useState([]);
  const [trainingDays, setTrainingDays] = useState({ limit: 0, balance: 0, training_day: [] });
  const [trainingExpenses, setTrainingExpenses] = useState({ limit: 0, balance: 0, training_budget: [] });

  const [loadingCount, setLoadingCount] = useState(0);
  const decreaseLoadingCount = () => setLoadingCount((n) => n - 1);
  useEffect(() => {
    setLoadingCount(4);

    let companyId = user.company.id;
    if (impersonatedUserId) {
      companyId = usersById[impersonatedUserId].company.id;
    }
    setBooksBudget(configuration.booksBudget.find(i => i.company === companyId)?.limit ?? 0)
    setSmartWorkingBudget(configuration.smartWorkingBudget.find(i => i.company === companyId)?.limit ?? 0)

    getTrainingDays(year, username)
      .then(setTrainingDays)
      .finally(decreaseLoadingCount);
    getTrainingExpenses(year, username)
      .then(setTrainingExpenses)
      .finally(decreaseLoadingCount);
    getExpenses(EXPENSE_BONUS_BOOKS, year, username)
      .then(setBookExpenses)
      .finally(decreaseLoadingCount);
    getExpenses(EXPENSE_BONUS_SMART_WORKING, year, username)
      .then(setSmartExpenses)
      .finally(decreaseLoadingCount);
    getExpenses(EXPENSE_BOOKS_BUDGET, year, username)
        .then(setBookExpensesNew)
        .finally(decreaseLoadingCount);
    getExpenses(EXPENSE_SMART_WORKING, year, username)
        .then(setSmartExpensesNew)
        .finally(decreaseLoadingCount);
  }, [impersonatedUserId, username, usersById, year]);

  const allBookExpenses = [...bookExpenses, ...bookExpensesNew];
  const availableBooksBudget = booksBudget - _.sumBy(allBookExpenses, 'cost');
  const allSmartExpenses = [...smartExpenses, ...smartExpensesNew];
  const availableSmartWorkingBudget =
    smartWorkingBudget - _.sumBy(allSmartExpenses, 'cost');

  return (
    <div className="content__wrapper">
      {renderSpinner(loadingCount > 0)}
      <div className="headerPage content">
        <div className="title">Budget formazione e altri bonus</div>
        <div className="spacer" />
        <div className="d-flex gap-3 form-group">
          {canSeeOtherUsers && (
            <MonoSelectUser
              select={setImpersonatedUserId}
              users={users}
            />
          )}
          <select
            className="yearContainer"
            defaultValue={year}
            onChange={({ target: { value } }) => setYear(+value)}
          >
            {availableYears.map((n) => (
              <option value={+n} key={+n}>
                {n}
              </option>
            ))}
          </select>
        </div>
      </div>
      {isForbidden ? (
        <Unauthorized />
      ) : (
        <div className="content__overflow">
          <div className="tabsContainer">
            <Col md="12">
              <Tabs className="mb-3 tabs-chart" justify mountOnEnter>
                <Tab
                  eventKey="training_budget"
                  tabClassName={getClass(
                    trainingExpenses.balance,
                    trainingExpenses.limit
                  )}
                  title={
                    <TabWithChart
                      title="Budget formazione"
                      maxAvailable={trainingExpenses.limit}
                      available={trainingExpenses.balance}
                    />
                  }
                >
                  <TrainingExpensesDataGrid
                    expenses={trainingExpenses.training_budget}                    
                    available={trainingExpenses.balance}
                    budget={trainingExpenses.limit}  
                  />
                </Tab>
                <Tab
                  eventKey="training_days"
                  tabClassName={getClass(
                    trainingDays.balance,
                    trainingDays.limit
                  )}
                  title={
                    <TabWithChart
                      title="Giorni di formazione"
                      unit="giorni"
                      maxAvailable={trainingDays.limit}
                      available={trainingDays.balance}
                    />
                  }
                >
                  <TrainingDaysDataGrid
                    days={trainingDays.training_day}
                    available={trainingDays.balance}
                    budget={trainingDays.limit}                    
                  />
                </Tab>
                <Tab
                  eventKey="book"
                  tabClassName={getClass(availableBooksBudget, booksBudget)}
                  title={
                    <TabWithChart
                      title={t('bonus_books')}
                      maxAvailable={booksBudget}
                      available={availableBooksBudget}
                    />
                  }
                >
                  <BonusExpensesDataGrid
                    expenses={allBookExpenses}
                    available={availableBooksBudget}
                    budget={booksBudget}
                  />
                </Tab>
                <Tab
                  eventKey="smart_working"
                  tabClassName={getClass(
                    availableSmartWorkingBudget,
                    smartWorkingBudget
                  )}
                  title={
                    <TabWithChart
                      title={t('bonus_smart_working')}
                      maxAvailable={smartWorkingBudget}
                      available={availableSmartWorkingBudget}
                    />
                  }
                >
                  <BonusExpensesDataGrid
                    expenses={allSmartExpenses}
                    available={availableSmartWorkingBudget}
                    budget={smartWorkingBudget}
                  />
                </Tab>
              </Tabs>
            </Col>
          </div>
        </div>
      )}
    </div>
  );
};
