import PropTypes from 'prop-types';
import { createContext, useEffect, useMemo, useState } from 'react';
import { getUserList } from '../utils/API/user';
import { renderSpinner } from '../utils/Utility';

const UserListContext = createContext({
  users: [],
  usersById: {},
  usersByUsername: {},
  isLoadingUsers: false
});

const UserListContextProvider = ({ children }) => {
  const [users, setUsers] = useState([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(true);

  useEffect(() => {
    getUserList()
      .then(({ results }) =>
        setUsers(
          results.sort((a, b) =>
            (a.full_name || '').localeCompare(b.full_name || '')
          )
        )
      )
      .catch(() => setUsers([]))
      .finally(() => setIsLoadingUsers(false));
  }, []);

  const usersById = useMemo(
    () =>
      Object.freeze(Object.fromEntries(users.map((user) => [user.id, user]))),
    [users]
  );

  const usersByUsername = useMemo(
      () => Object.freeze(Object.fromEntries(users.map((user) => [user.username, user]))),
      [users]
  );

  return (
    <UserListContext.Provider value={{ users, usersById, usersByUsername }}>
      {isLoadingUsers
        ? renderSpinner(isLoadingUsers)
        : children
      }
    </UserListContext.Provider>
  );
};

UserListContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { UserListContext, UserListContextProvider };
