import {Form as FormikForm, Formik} from 'formik';
import FormItem from '../../components/Form/FormItem';
import {Button} from 'react-bootstrap';
import FormCheckbox from "../../components/Form/FormCheckbox";
import * as Yup from 'yup';
import {UsersSelector} from "../../components/User/UsersSelector";
import {UserListContext} from "../../context/UserListContext";
import {useContext, useEffect, useState, useRef, useCallback} from "react";
import AddIcon from '@mui/icons-material/Add';
import PropTypes from "prop-types";
import {saveAdminProject, updateProject} from "../../utils/API/projects";
import {EditableChip} from "../../components/Projects/Modal/EditableChip";
import {error} from "../../utils/notification";

const ProjectForm = ({
  close,
  editFormData,
  refreshProjects,
  isCreate
}) => {
  const {
    users,
    usersById,
    usersByUsername
  } = useContext(UserListContext);
  const [customIssues, setCustomIssues] = useState([]);
  const [newIssue, setNewIssue] = useState("");
  const [isAdding, setIsAdding] = useState(false);
  const [originalIssues, setOriginalIssues] = useState([]);
  const inputRef = useRef(null);

  const defaultIssues = ["UX/UI", "ADM", "DEV", "COM", "VARIE"];

  const debounce = (func, delay) => {
    let debounceTimer;
    return function (...args) {
      const context = this;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const handleChange = useCallback(
    debounce((value) => {
      setNewIssue(value);
    }, 300),
    []
  );

  async function onSubmit(values) {

    const {id} = values;

    const p = values.users.map((userId) => usersById[userId]).filter((u) => u);

    const formattedCustomIssues = customIssues.map(issue => typeof issue === 'object' ? issue : {label: issue});

    const allIssues = [...new Set([...values.issues, ...formattedCustomIssues.map(issue => issue.label)])];
    try {
      if (id) {
        const currentLabels = originalIssues.map(issue => issue.label);
        const addedIssues = allIssues.filter(label => !currentLabels.includes(label));
        const deletedLabels = currentLabels.filter(label => !allIssues.includes(label));
        const deletedIssues = originalIssues.filter(issue => deletedLabels.includes(issue.label));
        await updateProject(id, values, p, addedIssues, deletedIssues);
      } else {
        await saveAdminProject(values, p);
      }
    } catch (e) {
      error("Error submitting form:", e);
    } finally {
      await refreshProjects();
      close();
    }
  }

  useEffect(() => {
    if (editFormData != null) {
      setOriginalIssues(editFormData.issues || []);

      setCustomIssues(editFormData.issues?.filter(issue => {
        const issueValue = typeof issue === 'object' ? issue.label : issue;
        return !defaultIssues.includes(issueValue);
      }) || []);
    }
  }, [editFormData]);

  ProjectForm.propTypes = {
    close: PropTypes.func.isRequired,
    editFormData: PropTypes.shape({
      id: PropTypes.string,
      customer: PropTypes.string,
      label: PropTypes.string,
      users: PropTypes.arrayOf(PropTypes.string),
      issues: PropTypes.arrayOf(PropTypes.string),
      active: PropTypes.bool
    }),
    refreshProjects: PropTypes.func.isRequired,
    isCreate: PropTypes.bool
  };

  ProjectForm.defaultProps = {
    editFormData: null,
    isCreate: false
  };

  const addCustomIssue = (values, setFieldValue) => {
    const trimmedIssue = newIssue.trim();
    if (trimmedIssue !== "" && !customIssues.includes(trimmedIssue) && !values.issues.includes(trimmedIssue)) {
      const updatedIssues = [...values.issues, trimmedIssue];
      setCustomIssues([...customIssues, trimmedIssue]);
      setFieldValue("issues", updatedIssues);
      setNewIssue("");
      setIsAdding(false);
    }
  };

  const removeIssue = (index) => {
    const updatedIssues = customIssues.filter((_, i) => i !== index);
    setCustomIssues(updatedIssues);
  };

  const editIssue = (index, newValue) => {
    const updatedIssues = [...customIssues];
    updatedIssues[index] = newValue;
    setCustomIssues(updatedIssues);
  };

  const validationSchema = Yup.object().shape({
    customer: Yup.string().required('Campo obbligatorio'),
    label: Yup.string().required('Campo obbligatorio'),
    issues: Yup.array().of(Yup.string()),
    users: Yup.array().of(Yup.string()),
    active: Yup.bool()
  });

  const initialValues = {
    id: editFormData?.id || '',
    customer: editFormData?.customer || '',
    label: editFormData?.label || '',
    issues: editFormData?.issues?.filter(issue => defaultIssues.includes(typeof issue === 'object' ? issue.label : issue))?.map(issue => typeof issue === 'object' ? issue.label : issue) || [],
    active: editFormData?.active || isCreate,
    users: editFormData?.users?.map(username => usersByUsername?.[username.toLowerCase()]).filter(Boolean).sort((a, b) => a.full_name.localeCompare(b.full_name)).map(user => user.id) || []
  };

  const hasId = !!initialValues?.id;

  return (
    <Formik
      onSubmit={async (values, actions) => {
        await onSubmit(values, actions);
      }}
      validationSchema={validationSchema}
      initialValues={initialValues}
    >
      {({
        values,
        handleSubmit,
        setFieldValue
      }) => (
        <FormikForm noValidate onSubmit={handleSubmit}>
          <FormItem
            values={values.id}
            type="text"
            name="id"
            style={{display: 'none'}}
            value={undefined}
          />
          <FormItem type="input" name="customer" label="Cliente *"/>
          <FormItem type="input" name="label" label="Nome progetto *"/>

          <div style={{paddingTop: "8px"}}>
            <UsersSelector
              name="users"
              label="Utenti"
              availableUsers={users}
              usersById={usersById}
              selected={values.users}
              setSelected={(newSelected) => {
                setFieldValue("users", newSelected);
              }}
              fixedPromptLabel={true}
            />
          </div>

          <div style={{
            display: "flex",
            gap: "20px",
            flexWrap: "wrap",
            padding: "8px"
          }}>
            <FormCheckbox name="issues" value="UX/UI" checked={values.issues.includes("UX/UI")} label="UX/UI"/>
            <FormCheckbox name="issues" value="ADM" checked={values.issues.includes("ADM")} label="ADM"/>
            <FormCheckbox name="issues" value="DEV" checked={values.issues.includes("DEV")} label="DEV"/>
            <FormCheckbox name="issues" value="COM" checked={values.issues.includes("COM")} label="COM"/>
            <FormCheckbox name="issues" value="VARIE" checked={values.issues.includes("VARIE")} label="VARIE"/>
          </div>

          <div style={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            alignItems: "center",
            padding: "8px"
          }}>
            {customIssues.map((issue, index) => (
              <EditableChip
                key={index}
                label={typeof issue === 'object' ? issue.label : issue}
                onDelete={() => removeIssue(index, values)}
                onSave={(value) => editIssue(index, value)}
              />
            ))}
            {isAdding && (
              <EditableChip
                label={newIssue}
                onDelete={() => {
                  setIsAdding(false);
                  setNewIssue("");
                }}
                onValueChange={(value) => handleChange(value)}
                onSave={() => addCustomIssue(values, setFieldValue)}
                isAdding
              />
            )}
          </div>
          <div style={{paddingBottom: "8px"}}>
            <button
              type="button"
              onClick={() => {
                setIsAdding(true);
                setTimeout(() => {
                  if (inputRef.current) {
                    inputRef.current.focus();
                  }
                }, 0);
              }}
              style={{
                color: "var(--brand)",
                cursor: "pointer",
                background: "transparent",
                border: "none",
                display: "flex",
                alignItems: "center",
                fontSize: "12px"
              }}>
              <AddIcon sx={{
                color: "var(--brand)",
                fontSize: 24
              }}/> Aggiungi personalizzata
            </button>
          </div>

          <div style={{padding: "8px"}}>
            <FormCheckbox values={values.active} name="active" label="Progetto attivo"/>
          </div>

          <div className="spacer"/>
          <div className="buttonContent">
            <Button
              onClick={close}
              variant="secondary"
            >
              Annulla
            </Button>
            <Button
              variant="primary"
              type="submit"
            >
              {hasId ? 'Aggiorna' : 'Inserisci'}
            </Button>
          </div>
        </FormikForm>
      )}
    </Formik>
  );
};

export default ProjectForm;