import { Navigate, NavLink, Route, Routes } from 'react-router-dom';
import { Col, Tab } from 'react-bootstrap';
import {useContext, useEffect, useMemo, useState} from 'react';
import { getReport, getReportDetail } from '../../utils/API/reports';
import { UserContext } from '../../context/UserContext';
import { MonthNavHeader } from '../../components/Layout/MonthNavHeader';
import Kilometers from '../Kilometers';
import Expenses from '../Expenses';
import PanelTotal from '../../components/PanelTotal/panelTotal';
import ConfirmForm from '../confirmForm';

import {PERMISSIONS, ROUTES} from '../../constants';
import I3Modal from '../../components/Layout/I3Modal';
import { extractSummaryData } from '../../utils/Utility';
import { MonthContext } from '../../context/MonthContext';
import { useKeyPress } from '../../hooks/useKeyPress';
import {MonoSelectUser} from "../../components/Form/MonoSelectUser";
import {UserListContext} from "../../context/UserListContext";

const defaultModalVisibility = () => ({ trip: false, expense: false });

const xor = (a, b) => (a ? !b : b);

const Trips = () => {
  const { user, hasClaim } = useContext(UserContext);

  const { month } = useContext(MonthContext);
  const { users, usersById } = useContext(UserListContext);

  const [impersonatedUserId, setImpersonatedUserId] = useState(null);
  const selectedUser = useMemo(() => {
    if (impersonatedUserId) {
      return usersById[impersonatedUserId]?.username;
    }
    return user?.username;
  }, [impersonatedUserId, usersById, user]);

  const [report, setReport] = useState({});
  const [summary, setSummary] = useState({});
  const [showModal, setShowModal] = useState(defaultModalVisibility());
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [showModalTableConfirm, setShowModalTableConfirm] = useState(
    defaultModalVisibility()
  );

  const isOnKmPage = window.location.pathname === ROUTES.TRIPS_KILOMETERS;
  const isOnExpensePage = window.location.pathname === ROUTES.TRIPS_EXPENSES;
  const toggleVisibility = (current) => ({
    trip: xor(isOnKmPage, current.trip),
    expense: xor(isOnExpensePage, current.expense),
  });

  const [trips, setTrips] = useState([]);
  const [expenses, setExpenses] = useState([]);

  const handleShowModalConfirm = () => setShowModalConfirm(true);
  const handleHideModalConfirm = () => setShowModalConfirm(false);

  const isReportConfirmed = report.confirmed || !report.id;

  const handleShowModal = () => {
    if (isReportConfirmed) return;
    setShowModal(toggleVisibility);
  };

  const handleShowModalTableConfirm = () =>
    setShowModalTableConfirm(toggleVisibility);

  const tripsUpdated = (_trips) => setTrips(_trips);
  const expensesUpdated = (_expenses) => setExpenses(_expenses);

  const loadReport = async () => {
    setReport({});
    const reportData = await getReportDetail(
      month.format('YYYY'),
      month.format('MM'),
      selectedUser,
      'expenses'
    );
    setReport(reportData);
  };

  const updateSummary = async () => {
    setSummary({});
    const reportData = await getReport(
      month.format('YYYY'),
      month.format('MM'),
        selectedUser
    );
    const summaryReportData = extractSummaryData(reportData);
    setSummary(summaryReportData);
  };

  useEffect(() => loadReport().finally(updateSummary), [month, selectedUser]);

  useEffect(() => updateSummary(), [trips, expenses]);

  useKeyPress('F9', handleShowModal);
  useKeyPress('Escape', () => {
    setShowModal(defaultModalVisibility());
    setShowModalConfirm(false);
    setShowModalTableConfirm(defaultModalVisibility());
  });

  return (
    <>
      <div className="content__wrapper">
        <MonthNavHeader
          title="Nota spese e rimborso chilometrico"
          showInsertButton={!isReportConfirmed}
          onInsert={handleShowModal}
        >
          {hasClaim(PERMISSIONS.ADMINISTRATION) && (
          <MonoSelectUser
              select={setImpersonatedUserId}
              users={users}
          />
              )}
        </MonthNavHeader>
        <div className="content__overflow">
          <div className="tabsContainer">
            <Col md="12">
              <Tab.Container
                defaultActiveKey="km"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <div className="nav-tabs">
                  <NavLink to={ROUTES.KILOMETERS} className="nav-link">
                    Km
                  </NavLink>
                  <NavLink to={ROUTES.EXPENSES} className="nav-link">
                    NOTA SPESE
                  </NavLink>
                </div>
                <Tab.Content>
                  <Routes>
                    <Route
                      path={ROUTES.KILOMETERS}
                      element={
                        <Kilometers
                          isReportConfirmed={isReportConfirmed}
                          showModal={showModal.trip}
                          handleShowModal={handleShowModal}
                          handleHideModal={handleShowModal}
                          showModalTableConfirm={showModalTableConfirm}
                          handleShowModalTableConfirm={
                            handleShowModalTableConfirm
                          }
                          handleHideModalTableConfirm={
                            handleShowModalTableConfirm
                          }
                          month={month}
                          selectedUser={selectedUser}
                          tripsUpdated={tripsUpdated}
                        />
                      }
                    />
                    <Route
                      path={ROUTES.EXPENSES}
                      element={
                        <Expenses
                          isReportConfirmed={isReportConfirmed}
                          showModal={showModal.expense}
                          handleShowModal={handleShowModal}
                          handleHideModal={handleShowModal}
                          showModalTableConfirm={showModalTableConfirm}
                          handleShowModalTableConfirm={
                            handleShowModalTableConfirm
                          }
                          handleHideModalTableConfirm={
                            handleShowModalTableConfirm
                          }
                          month={month}
                          selectedUser={selectedUser}
                          expensesUpdated={expensesUpdated}
                        />
                      }
                    />
                    <Route
                      path="*"
                      element={<Navigate to={ROUTES.TRIPS_KILOMETERS} />}
                    />
                  </Routes>
                </Tab.Content>
              </Tab.Container>
            </Col>
          </div>
        </div>
      </div>
      <PanelTotal
        report={report}
        summaryData={summary}
        handleShowModalConfirm={handleShowModalConfirm}
      />

      <I3Modal
        show={showModalConfirm}
        onHide={handleHideModalConfirm}
        title="Chiudi mese"
      >
        <ConfirmForm
          close={handleHideModalConfirm}
          month={month}
          reloadReport={loadReport}
        />
      </I3Modal>
    </>
  );
};

export default Trips;
