import {useEffect, useState} from "react";
import {Button} from 'react-bootstrap';
import {PlusCircle} from 'react-bootstrap-icons';
import ProjectsDataGrid from "./ProjectsDataGrid";
import {PROJECT_GROUP_BY} from "../../constants";
import {getProjectsNotInternal, deleteProjectNotInternalById} from "../../utils/API/projects";
import I3Modal from "../../components/Layout/I3Modal";
import ProjectForm from "./ProjectsForm";
import {error, success} from "../../utils/notification";
import I3ModalConfirmation from "../../components/Layout/I3ModalConfirmation";
import {deleteIssue, saveIssue} from "../../utils/API/issues";

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editFormData, setEditFormData] = useState(null);
  const [isCreate, setIsCreate] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(null);

  useEffect(() => {
    const fetchProjects = async () => {
      const data = await getProjectsNotInternal();
      setProjects(data);
    };
    fetchProjects();
  }, []);

  const handleDeleteProjectById = async () => {
    try {
      const project = projects.find(p => p.id === selectedProjectId);
      if (!project) {
        error("Progetto non trovato");
        return;
      }

      let haveError = false;
      const deletedIssues = [];

      for (const issue of project.issues) {
        // eslint-disable-next-line no-await-in-loop
        const issueDeleted = await deleteIssue(issue.id);
        if (!issueDeleted) {
          haveError = true;
          break;
        }
        deletedIssues.push(issue);
      }

      if (haveError) {
        for (const issue of deletedIssues) {
          // eslint-disable-next-line no-await-in-loop
          await saveIssue(issue.label, project.id);
        }
        error("Impossibile eliminare il progetto, ci sono issue assegnate con worklog attivi");
      } else {
        const response = await deleteProjectNotInternalById(selectedProjectId);
        if (response) {
          success("Progetto eliminato con successo");
          refreshProjects();
        }
      }
    } catch (e) {
      error(`Errore durante l'eliminazione del progetto: ${e}`);
    } finally {
      setShowDeleteConfirmationModal(false);
    }
  };

  const handleToggleModal = () => setShowModal(!showModal);

  const handleAddClick = () => {
    setEditFormData({id: undefined});
    setShowModal(true);
    setIsCreate(true);
  };

  const refreshProjects = async () => {
    const data = await getProjectsNotInternal();
    setProjects(data);
  };

  const handleEditClick = (project) => {
    setEditFormData(project);
    setShowModal(true);
    setIsCreate(false);
  };
  const handleDeleteClick = (projectId) => {
    setSelectedProjectId(projectId);
    setShowDeleteConfirmationModal(true);
  }

  return (<>
    <div className="projects-page">
      <div className="headerPage content">
        <div className="title">Progetti</div>
        <div className="spacer"/>
        <div className="buttonGroup">
          <Button variant="primary" onClick={() => handleAddClick()}>
            <PlusCircle/>
            Inserisci
          </Button>
        </div>
      </div>
      <div className="projects-grid">
        <ProjectsDataGrid data={projects ?? []} groupBy={PROJECT_GROUP_BY.NONE} onEditClick={handleEditClick}
                          deleteProjectById={handleDeleteClick}/>
      </div>
      <I3Modal
        show={showModal}
        onHide={handleToggleModal}
        title={isCreate ? "Inserisci nuovo progetto" : "Modifica progetto"}
        className="I3Modal-log-time"
      >
        <ProjectForm
          close={handleToggleModal}
          editFormData={editFormData}
          refreshProjects={refreshProjects}
          isCreate={isCreate}/>
      </I3Modal>
      <I3ModalConfirmation
        show={showDeleteConfirmationModal}
        onHide={() => setShowDeleteConfirmationModal(false)}
        title="Cancella progetto"
        questionTitle="Vuoi cancellare questo progetto?"
        onConfirm={handleDeleteProjectById}
      />
    </div>
  </>);
}

export default Projects;