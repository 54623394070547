import {API} from '../../constants';
import request from '../axios';
import {getFormData} from "../formUtils";
import {notifyResponseError, success, error} from "../notification";
import {deleteIssue, saveIssue} from "./issues";

export const getProjects = async () => {
  const res = await request.get(API.PROJECTS);
  return res.data;
}

export const getProjectsNotInternal = async () => {
  const res = await request.get(API.PROJECTS_NOT_INTERNAL);
  return res.data;
}

export const deleteProjectNotInternalById = async (projectId) => {
  try {
    await request.delete(`${API.PROJECTS}${projectId}/`);
  } catch (e) {
    return false
  }
  return true
}

export const getUserProjects = async () => {
  const res = await request.get(API.ME_PROJECTS);
  return res.data;
}

export const saveAdminProject = (values, users) => {
  const userIds = users.map((user) => user.id);

  return request.post(`${API.PROJECTS_NOT_INTERNAL}`, getFormData({
    ...values,
    users: userIds
  }), {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).then((response) => {
    const projectId = response.data.id;

    for (const issue of values.issues) {
      saveIssue(issue, projectId);
    }

    success('Esito', 'Salvataggio riuscito');
  }).catch((e) => {
    notifyResponseError(e);
    throw e;
  });
};

export const updateProject = async (id, data, users, addedIssues, deletedIssues) => {
  const userIds = users.map((user) => parseInt(user.id, 10));


  try {
    const response = await request.put(`${API.PROJECTS}${id}/`, getFormData({
      ...data,
      users: userIds
    }), {
      headers: {'Content-Type': 'multipart/form-data'}
    });

    const updatedProjectId = response.data.id;

    if (addedIssues.length > 0) {
      await Promise.all(addedIssues.map(label => saveIssue(label, updatedProjectId)));
    }

    if (deletedIssues.length > 0) {
      try {
        const labels = [];
        if (Array.isArray(deletedIssues)) {
          for (const issue of deletedIssues) {
            // eslint-disable-next-line no-await-in-loop
            const result = await deleteIssue(issue.id);
            if (!result) labels.push(issue.label);
          }
        } else {
          console.error("deletedIssues is not an array or is undefined");
        }
        // eslint-disable-next-line no-console
        if (labels?.length > 0) {
          error(`Impossibile eliminare issue con worklog attivi: ${labels.join(', ')}`);
        }
      } catch (e) {
        error(`error: ${e}`)
      }
    }

    success('Esito', 'Salvataggio riuscito');
  } catch (e) {
    notifyResponseError(e);
    throw e;
  }
};
