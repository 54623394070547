import _ from 'lodash';
import request from '../axios';
import { API } from '../../constants';
import { error, notifyResponseError, success } from '../notification';

export const getTrips = (momentDate, selectedUser) =>
  request
    .get(
      `${API.TRIPS}?year=${momentDate.format('YYYY')}&month=${momentDate.format(
        'MM'
      )}&username=${selectedUser}`
    )
    .then((res) => _.get(res, 'data.results', []));

export const saveTrip = (values) =>
  request
    .post(API.TRIPS, values)
    .then(() => {
      success('Esito', 'Salvataggio riuscito');
    })
    .catch((e) => {
      notifyResponseError(e);
      throw e;
    });

export const updateTrip = (trip) =>
  request
    .put(`${API.TRIPS}${trip.id}/`, trip)
    .then(() => {
      success('Esito', 'Aggiornamento eseguito con successo');
    })
    .catch((e) => {
      notifyResponseError(e);
      throw e;
    });

export const deleteTrip = (id) =>
  request
    .delete(`${API.TRIPS}${id}`)
    .then(() => {
      success('Esito', 'Cancellazione avvenuta con successo');
    })
    .catch((e) => {
      error('Errore', 'Cancellazione fallita');
      throw e;
    });

export const getTrip = (id) =>
  request
    .get(`${API.TRIPS}${id}`)
    .then((res) => _.get(res, 'data', {}))
    .catch((e) => {
      error('Errore', 'Cancellazione fallita');
      throw e;
    });
export default getTrips;
