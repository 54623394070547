import {API} from '../../constants';
import request from '../axios';
import {getFormData} from "../formUtils";

export const getIssues = async () => {
  const res = await request.get(API.ISSUES);
  return res.data;
}

export const getUserIssues = async () => {
  const res = await request.get(API.ME_ISSUES);
  return res.data;
}

export const saveIssue = (issueName, projectId) => request.post(`${API.PROJECT_ISSUES}`, getFormData({
  label: issueName,
  project: projectId,
}), {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
})

export const deleteIssue = (id) =>
  request.delete(`${API.PROJECT_ISSUES}${id}`).then(() => true).catch(() => false);