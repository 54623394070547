import {useState} from "react";
import PropTypes from "prop-types";
import CustomChip from "../Chips/CustomChip";
import {VerticalBarChart} from "../Charts/BarChart";
import {Box} from "@mui/material";
import Icon from "../Icon";

const GoalItem = ({name, percentage, maxValue, currentYearValue, previousYearValue, prevYearData, currentYearData}) => {

    const [hoverData, setHoverData] = useState(null); // State to show reactive data on hover

    const getIconName = () => percentage > 0 ? "tabler:triangle-filled" : "tabler:triangle-inverted-filled";
    const getIconColor = () => (percentage > 0 ? "#17A905" : "#C20600");
    const transformPercentage = () => `${percentage}`.replace("-", "");

    // Handler to display reactive data (executed on hover)
    const handleMouseEnter = () => {
        setHoverData({
            title: "Chart Insights", value: `Current: ${currentYearValue}, Previous: ${previousYearValue}`
        });
    };

    const handleMouseLeave = () => {
        setHoverData(null);
    };

    return (<div className="goal-item">
        <div
            className="charts"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <VerticalBarChart
                maxValue={maxValue}
                value={currentYearValue}
                backgroundColor="#E5F3FF"
                color="#0075E5"
                style={{width: "10px"}}
            />
            <VerticalBarChart
                maxValue={maxValue}
                value={previousYearValue}
                backgroundColor="#e3dcf0"
                color="#7452B3"
                style={{width: "10px"}}
            />
            {hoverData && (<Box className="hover-box">
                <div className="hover-box-left">
                    <span className="hover-box-total">{currentYearValue}</span>
                    <div className="hover-box-single-stats">
                        <div className="stat">
                            <Icon name="material-symbols:circle" color="rgba(23, 169, 5, 1)" size="10px"/>
                            <span>
                                {currentYearData.achieved}
                            </span>
                        </div>
                        <div className="stat">
                            <Icon name="material-symbols:circle" color="rgba(235, 150, 7, 1)" size="10px"/>
                            <span>
                                {currentYearData.partiallyAchieved}
                            </span>
                        </div>
                        <div className="stat">
                            <Icon name="material-symbols:circle" color="rgba(229, 8, 0, 1)" size="10px"/>
                            <span>
                                {currentYearData.notAchieved}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="hover-box-right">
                    <span className="hover-box-total">{previousYearValue}</span>
                    <div className="hover-box-single-stats">
                        <div className="stat">
                            <Icon name="material-symbols:circle" color="rgba(23, 169, 5, 1)" size="10px"/>
                            <span>
                                {prevYearData.achieved}
                            </span>
                        </div>
                        <div className="stat">
                            <Icon name="material-symbols:circle" color="rgba(235, 150, 7, 1)" size="10px"/>
                            <span>
                                {prevYearData.partiallyAchieved}
                            </span>
                        </div>
                        <div className="stat">
                            <Icon name="material-symbols:circle" color="rgba(229, 8, 0, 1)" size="10px"/>
                            <span>
                                {prevYearData.notAchieved}
                            </span>
                        </div>
                    </div>
                </div>
            </Box>)}
        </div>
        <CustomChip
            value={`${transformPercentage()}%`}
            iconName={percentage === 0 ? "" : getIconName()}
            iconColor={getIconColor()}
            iconSize="12"
        />
        <div className="name">{name}</div>
    </div>);
};

GoalItem.propTypes = {
    name: PropTypes.string.isRequired,
    percentage: PropTypes.number,
    maxValue: PropTypes.number.isRequired,
    currentYearValue: PropTypes.number.isRequired,
    previousYearValue: PropTypes.number.isRequired,
    prevYearData: PropTypes.arrayOf(PropTypes.object).isRequired,
    currentYearData: PropTypes.arrayOf(PropTypes.object).isRequired,
};

GoalItem.defaultProps = {
    percentage: 0,
};

export default GoalItem;
