import {Icon as Iconify} from "@iconify/react";
import PropTypes from "prop-types";

const Icon = ({size, name, color}) => <div style={{color, display: "flex", alignItems: "center"}}>
    <Iconify height={size} icon={name} width={size}/>
</div>

Icon.defaultProps = {
    size: 36,
    color: ""
}

Icon.propTypes = {
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    name: PropTypes.string.isRequired,
}

export default Icon;