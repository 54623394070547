import PropTypes from 'prop-types';
import {Box, Card} from '@mui/material';
import getPercentDifference from "../../utils/getPercentDifference";
import CustomChip from "../Chips/CustomChip";

export const RetrospectiveReportCard = ({
                                            title,
                                            icon,
                                            iconBackgroundColor,
                                            iconColor,
                                            data,
                                        }) => {
    const ICON_STYLE = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '3px',
        backgroundColor: iconBackgroundColor,
        color: iconColor,
    };

    const DATA_STYLE = (isFirst) => ({
        color: isFirst ? '#0075E5' : '#7452B3',
    });

    const getAnnualPercentageDifference = () =>
        !isNaN(getPercentDifference(data[0]?.value, data[1]?.value))
            ? `${Math.abs(getPercentDifference(data[0]?.value, data[1]?.value))}%`
            : 'n.d.';

    return (
        <Card
            sx={{border: 'solid', borderRadius: '8px'}}
            variant="outlined"
            className="retrospective-card"
        >
            <div className="retrospective-card-title">
                <Box className="icon" sx={ICON_STYLE}>
                    {icon}
                </Box>
                <span className="title">{title}</span>
            </div>
            <div className="retrospective-card-content">
                {data.map(
                    ({header, value, isPercentage, footer}, index) => (
                        <div
                            key={index}
                            className="retrospective-card-data"
                            style={DATA_STYLE(index === 0)}
                        >
                            <div className="retrospective-card-data-header">
                                <span className="retrospective-card-data-header-title">
                                    {header}
                                </span>
                                {index === 0 && (
                                    <CustomChip value={getAnnualPercentageDifference()}
                                                iconName={getPercentDifference(data[0]?.value, data[1]?.value) > 0 ? 'mdi:plus' : 'mdi:minus'}
                                                iconSize="12px"
                                    />
                                )}
                            </div>
                            <div className="retrospective-card-data-body">
                                {value == null
                                    ? ('n.d.')
                                    : (
                                        <span>
                                            {value}
                                            {isPercentage ? <span className="data-body-percentage">%</span> : ''}
                                        </span>
                                    )}
                            </div>
                            {footer && (
                                <div className="retrospective-card-data-footer">
                                    {footer}
                                </div>
                            )}
                        </div>
                    )
                )}
            </div>
        </Card>
    );
};

RetrospectiveReportCard.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.node,
    iconBackgroundColor: PropTypes.string.isRequired,
    iconColor: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.shape({
        header: PropTypes.string.isRequired,
        value: PropTypes.number,
        isPercentage: PropTypes.bool,
        footer: PropTypes.string
    }))).isRequired,
};

RetrospectiveReportCard.defaultProps = {
    icon: null
};