import { Spinner } from 'react-bootstrap';
import { useContext, useEffect } from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes, } from 'react-router-dom';
import { UserContext } from './context/UserContext';
import Navbar from './components/Navbar';
import Profile from './containers/Profile';
import { WorklogPage } from './containers/Worklog/WorklogPage';
import Administration from './containers/Administration';
import { PERMISSIONS, ROUTES } from './constants';
import Styled from 'styled-components';
import Trips from './containers/Trips';
import { useKeycloak } from '@react-keycloak/web';
import { ReportPage } from './containers/Report/ReportPage';
import { MonthContextProvider } from './context/MonthContext';
import { BudgetPage } from './containers/Budget/BudgetPage';
import { ViewAbsencesPage } from './containers/Absences/ViewAbsencesPage';
import { AdminBudgetPage } from './containers/AdminBudget/AdminBudgetPage';
import { UserListContextProvider } from './context/UserListContext';
import PropTypes from 'prop-types';
import { BudgetContextProvider } from './context/BudgetContext';
import { initAuth } from './utils/axios';
import { AbsencesPage } from './containers/Absences/AbsencesPage';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { CertificationsPage } from './containers/Certifications/CertificationsPage';
import { EarnedCertificationsPage } from './containers/EarnedCertifications/EarnedCertificationsPage';
import { EarnedCertificationsAlert } from './components/EarnedCertificationsAlert';
import { RetrospectiveGoalTypesPage as RetrospectiveGoalTypesPage } from './containers/RetrospectiveGoalTypes/RetrospectiveGoalTypesPage';
import { RetrospectiveGoalsPage } from './containers/RetrospectiveGoals/RetrospectiveGoalsPage';
import { RetrospectivePage } from './containers/Retrospective/RetrospectivePage';
import { RetrospectiveEditPage } from './containers/Retrospective/RetrospectiveEditPage';
import Projects from "./containers/Projects";

const theme = createTheme({
  typography: {
    fontFamily: '"Open Sans", sans-serif;',
  },
  palette: {
    secondary: {
      main: '#ffffff',
    },
    error: {
      main: '#ff0000',
    },
  },
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: 0,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'primary' && {
              '&:hover': {
                color: '#ffffff',
              },
            }),
        }),
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#fff',
          [`& .MuiTooltip-arrow`]: {
            "&:before": {
              color: '#fff'
            },
          }
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        selectLabel: {
          marginBottom: 0,
        },
        displayedRows: {
          marginBottom: 0,
        }
      }
    }
  },
});

const AppContainer = Styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledSpinner = Styled(Spinner)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateXY(-50%,-50%)
`;

const LoadingSpinner = () => (
  <StyledSpinner animation="border" variant="info" />
);
const Root = () => {
  const { user, hasClaim } = useContext(UserContext);
  let root = ROUTES.PROFILE;

  if (user.is_employee) root = ROUTES.WORKLOG;
  else if (hasClaim(PERMISSIONS.VIEW_ABSENCES)) root = ROUTES.VIEW_ABSENCES;
  else if (hasClaim(PERMISSIONS.ADMINISTRATION)) root = ROUTES.ADMINISTRATION;

  return <Navigate to={root} />;
};

const AuthRoutes = () => (
  <Routes>
    <Route path={ROUTES.PROFILE} element={<Profile />} />
    <Route path={`${ROUTES.TRIPS}/*`} element={<Trips />} />
    <Route path={ROUTES.WORKLOG} element={<WorklogPage />} />
    <Route path={ROUTES.BUDGET} element={<BudgetPage />} />
    <Route path={ROUTES.ADMINISTRATION} element={<Outlet />}>
      <Route index element={<Administration />} />
      <Route path={ROUTES.ADMIN_BUDGET} element={<AdminBudgetPage />} />
    </Route>
    <Route path={ROUTES.REPORT} element={<ReportPage />} />
    <Route path={ROUTES.VIEW_ABSENCES} element={<ViewAbsencesPage />} />
    <Route path={ROUTES.ABSENCES} element={<AbsencesPage />} />
    <Route path={ROUTES.CERTIFICATIONS} element={<CertificationsPage />} />
    <Route path={ROUTES.EARNED_CERTIFICATIONS} element={<EarnedCertificationsPage />} />
    <Route path={ROUTES.RETROSPECTIVE_GOAL_TYPES} element={<RetrospectiveGoalTypesPage />} />
    <Route path={ROUTES.RETROSPECTIVE_GOALS} element={<RetrospectiveGoalsPage />} />
    <Route path={ROUTES.RETROSPECTIVE} element={<RetrospectivePage />} />
    <Route path={`${ROUTES.RETROSPECTIVE}/:id`} element={<RetrospectiveEditPage />} />
    <Route path={`${ROUTES.PROJECTS}`} element={<Projects/>} />
    <Route path="*" element={<Root />} />
  </Routes>
);

const Providers = ({ children }) => (
  <ThemeProvider theme={theme}>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MonthContextProvider>
        <UserListContextProvider>
          <BudgetContextProvider>{children}</BudgetContextProvider>
        </UserListContextProvider>
      </MonthContextProvider>
    </LocalizationProvider>
  </ThemeProvider>
);

Providers.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function App() {
  const { user, login, logout } = useContext(UserContext);
  const { initialized, keycloak } = useKeycloak();
  const authenticated = keycloak?.authenticated;

  useEffect(() => {
    if (initialized) {
      initAuth(keycloak, login, logout);
    }
  }, [initialized, authenticated, login, logout]);

  if (initialized) {
    if (!keycloak.authenticated) {
      keycloak?.login();
    }
  }
  return (
    <BrowserRouter>
      {initialized && keycloak.authenticated && (
        <Providers>
          <AppContainer>
            <EarnedCertificationsAlert />
            <div style={{ display:'flex', flexDirection:'row', flex:'1 0 auto' }}>
              <Navbar />
              <div className="content">
                {user.username ? <AuthRoutes /> : <LoadingSpinner />}
              </div>
            </div>
          </AppContainer>
        </Providers>
      )}
    </BrowserRouter>
  );
}
