import { API } from '../../constants';
import request from '../axios';
import { error } from '../notification';

export const getRetrospectiveGoalsByYearAndUsername = async (year, username = '') => {
  try {
    const { data } = await request.get(`${API.RETROSPECTIVE_GOALS}?year=${year}&username=${username}`);
    return data;
  } catch (e) {
    error('Errore', 'Caricamento fallito');
    throw e;
  }
};

export const getRetrospectiveGoalsReportByYearCompanyAndUsernames = async (year, company, usernames = []) => {
  try {
    const { data } = await request.get(`${API.RETROSPECTIVE_GOALS}report?year=${year}&company=${company}&usernames=${usernames}`);
    return data;
  } catch (e) {
    error('Errore', 'Caricamento fallito');
    throw e;
  }
};

export const getRetrospectiveSingleGoalsByYearCompanyAndUsernames = async (year, company, usernames = []) => {
  try {
    const {data} = await request.get(`${API.RETROSPECTIVE_GOALS}report-by-type?year=${year}&company=${company}&usernames=${usernames}`);
    return data;
  } catch (e) {
    error('Errore', e.message);
    throw e;
  }
}
