import _ from 'lodash';
import request from '../axios';
import { API } from '../../constants';
import { error, notifyResponseError, success } from '../notification';
import { getFormData } from '../formUtils';
import { getUserParams } from './paramUtil';

export const getExpenses = (momentDate, selectedUser) => {
  if (!momentDate) return [];
  const y = `year=${momentDate.format('YYYY')}`;
  const m = `month=${momentDate.format('MM')}`;
  return request
    .get(`${API.EXPENSES_NOT_TRIP}?category=expense&${y}&${m}&username=${selectedUser}`)
    .then((res) => _.get(res, 'data.results', []));
};

export const getExpensesByTypeAndYear = (type, year, username = '') =>
  request
    .get(
      `${API.EXPENSES_BY_TYPE}?type=${type}&year=${year}&username=${username}`
    )
    .then((res) => res?.data ?? []);

export const getExpensesByTypeAndYearAdmin = (company, type, year, usernames = []) =>
  request
    .get(
      `${API.EXPENSES_BY_TYPE_ADMIN}?company=${company}&type=${type}&year=${year}&${getUserParams(
        usernames
      )}`
    )
    .then((res) => res?.data ?? []);

export const saveExpense = (values) => {
  const formData = getFormData(values);

  return request
    .post(`${API.EXPENSES}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(() => {
      success('Esito', 'Salvataggio riuscito');
    })
    .catch((e) => {
      notifyResponseError(e);
      throw e;
    });
};

export const updateExpense = (values) => {
  const formData = getFormData(values);

  return request
    .put(`${API.EXPENSES}${values.id}/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(() => {
      success('Esito', 'Aggiornamento eseguito con successo');
    })
    .catch((e) => {
      notifyResponseError(e);
      throw e;
    });
};

export const deleteExpense = (id) =>
  request
    .delete(`${API.EXPENSES}${id}`)
    .then(() => {
      success('Esito', 'Cancellazione avvenuta con successo');
    })
    .catch((e) => {
      error('Errore', 'Cancellazione fallita');
      throw e;
    });

export const getExpense = (id) =>
  request
    .get(`${API.EXPENSES}${id}`)
    .then((res) => _.get(res, 'data', {}))
    .catch((e) => {
      error('Errore', 'Cancellazione fallita');
      throw e;
    });

export default getExpenses;
