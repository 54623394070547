import PropTypes from 'prop-types';
import {RetrospectiveReportCard} from '../../components/Report/RetrospectiveReportCard';
import Icon from "../../components/Icon";
import GoalGraphic from "../../components/Report/GoalGraphic";


export const RetrospectiveReports = ({data, retroSingleGoals}) => {
    const {currentYear, previousYear, total, outcomes} = data;
    const outcomesCurrentYear = Object.values(outcomes).reduce((sum, outcome) => sum + outcome.currentYear, 0);
    const outcomesPreviousYear = Object.values(outcomes).reduce((sum, outcome) => sum + outcome.previousYear, 0);


    const getPercentage = (v, tot) => {
        if (tot === 0) {
            return null;
        }
        return Math.round((v / tot) * 100).toFixed(0);
    };

    const getFooter = (v, tot) => `${v}/${tot} valutati`;

    return (
        <div className="retrospective-reports">
            <div className="retrospective-cards">
                <>
                    <RetrospectiveReportCard title="Obiettivi totali"
                                             icon={<Icon name="fluent-mdl2:bullseye" size="16.1"/>}
                                             iconBackgroundColor="#E5F3FF"
                                             iconColor="#0075E5"
                                             data={[
                                                 {
                                                     header: currentYear,
                                                     value: total.currentYear,
                                                     footer: `${outcomesCurrentYear} valutati`
                                                 },
                                                 {
                                                     header: previousYear,
                                                     value: total.previousYear,
                                                     footer: `${outcomesPreviousYear} valutati`
                                                 }
                                             ]}/>
                    <RetrospectiveReportCard title="Obiettivi raggiunti"
                                             icon={<Icon name="tabler:circle-check" size="20"/>}
                                             iconBackgroundColor="#ECF8F2"
                                             iconColor="#0F6D03"
                                             data={[
                                                 {
                                                     header: currentYear,
                                                     value: getPercentage(outcomes.achieved.currentYear, outcomesCurrentYear),
                                                     isPercentage: true,
                                                     footer: getFooter(outcomes.achieved.currentYear, outcomesCurrentYear)
                                                 },
                                                 {
                                                     header: previousYear,
                                                     value: getPercentage(outcomes.achieved.previousYear, outcomesPreviousYear),
                                                     isPercentage: true,
                                                     footer: getFooter(outcomes.achieved.previousYear, outcomesPreviousYear)
                                                 }
                                             ]}/>
                    <RetrospectiveReportCard title="Obiettivi parziali"
                                             icon={<Icon name="tabler:progress-check" size="20.15"/>}
                                             iconBackgroundColor="#FDEFD8"
                                             iconColor="#955F04"
                                             data={[
                                                 {
                                                     header: currentYear,
                                                     value: getPercentage(outcomes.partiallyAchieved.currentYear, outcomesCurrentYear),
                                                     isPercentage: true,
                                                     footer: getFooter(outcomes.partiallyAchieved.currentYear, outcomesCurrentYear)
                                                 },
                                                 {
                                                     header: previousYear,
                                                     value: getPercentage(outcomes.partiallyAchieved.previousYear, outcomesPreviousYear),
                                                     isPercentage: true,
                                                     footer: getFooter(outcomes.partiallyAchieved.previousYear, outcomesPreviousYear)
                                                 }
                                             ]}/>
                    <RetrospectiveReportCard title="Obiettivi non raggiunti"
                                             icon={<Icon name="tabler:circle-x" size="20"/>}
                                             iconBackgroundColor="#FFF0F0"
                                             iconColor="#C20600"
                                             data={[
                                                 {
                                                     header: currentYear,
                                                     value: getPercentage(outcomes.notAchieved.currentYear, outcomesCurrentYear),
                                                     isPercentage: true,
                                                     footer: getFooter(outcomes.notAchieved.currentYear, outcomesCurrentYear)
                                                 },
                                                 {
                                                     header: previousYear,
                                                     value: getPercentage(outcomes.notAchieved.previousYear, outcomesPreviousYear),
                                                     isPercentage: true,
                                                     footer: getFooter(outcomes.notAchieved.previousYear, outcomesPreviousYear)
                                                 }
                                             ]}/>
                </>
            </div>
            <GoalGraphic data={retroSingleGoals}/>
        </div>
    );
};

RetrospectiveReports.propTypes = {
    data: PropTypes.objectOf(PropTypes.object).isRequired,
    retroSingleGoals: PropTypes.objectOf(PropTypes.object).isRequired,
};

RetrospectiveReports.defaultProps = {};
