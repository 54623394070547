import _ from 'lodash';
import { columnsFromData } from '../../components/Table/utility';
import { CurrencyCell } from '../../components/Cells/CurrencyCell';

const columnOrder = [
  'rowNumber',
  'date',
  'type',
  'description',
  'cost',
  'attachment',
  'invoice_made_out_to_company',
  'action',
];

const prepareTableExpenses = ({ data, isReportConfirmed }) => {
  const columns = columnsFromData({ data, headerPrefix: 'expenses' });
  const costColumn = _.find(columns, ['Header', 'expenses.cost']);
  if (costColumn) {
    costColumn.Cell = CurrencyCell;
  }

  const hiddenColumns = [
    'id',
    'report',
    'category',
    'editable',
    'real_cost',
    'report',
    'training_budget',
    'is_guild',
    'trip'
  ];

  if (isReportConfirmed) {
    hiddenColumns.push('action');
  }

  const getCellProps = (cell) => {
    if (cell.row.values.type === 'cash_advance') {
      // eslint-disable-next-line no-param-reassign
      cell.column.className = 'expenses';
    } else {
      // eslint-disable-next-line no-param-reassign
      cell.column.className = '';
    }
    return {};
  };

  return {
    columns,
    columnOrder,
    hiddenColumns,
    getCellProps,
  };
};

export default prepareTableExpenses;
