import PropTypes from "prop-types";

export const VerticalBarChart = ({maxValue, value, color, backgroundColor, style = {}}) => {

    const calculateWidth = () => (value / maxValue) * 100;

    return <div className="vertical-bar-chart" style={{...style, backgroundColor}}>
        <div className="bar" style={{
            height: `${calculateWidth()}%`,
            backgroundColor: color,
            borderTopLeftRadius: value === maxValue && "100px",
            borderTopRightRadius: value === maxValue && "100px"
        }}/>
    </div>
}

VerticalBarChart.defaultProps = {
    color: '#083ad4',
    backgroundColor: '#5375d2',
    style: {},
}

VerticalBarChart.propTypes = {
    maxValue: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    color: PropTypes.string,
    backgroundColor: PropTypes.string,
    style: PropTypes.shape({}),
}
