import PropTypes from "prop-types";
import Icon from "../../Icon";
import {useEffect, useRef, useState} from "react";

export const EditableChip = ({
  label,
  onDelete,
  onSave,
  onValueChange,
  isAdding,
}) => {
  const [isEditing, setIsEditing] = useState(isAdding);
  const [editedLabel, setEditedLabel] = useState(label);
  const inputRef = useRef(null);

  const handleDelete = () => {
    if (isEditing) {
      setEditedLabel(label);
      setIsEditing(false);
      onDelete()
    } else {
      onDelete();
    }
  }
  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  return (<div
      style={{
        fontSize: '14px',
        backgroundColor: "rgba(244, 245, 246, 1)",
        borderRadius: '100px',
        padding: '3px 10px',
        fontWeight: 400,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        gap: "4px"
      }}
    >
      {isEditing ? (<div>
          <input
            ref={inputRef}
            style={{
              padding: "2px 8px",
              borderRadius: "4px",
              border: "1px solid var(--brand)",
              height: "25px",
              maxWidth: "100px",
            }}
            value={editedLabel ?? ""}
            onChange={(e) => {
              setEditedLabel(e.target.value);
              onValueChange(e.target.value);
            }}
          />
        </div>) : (<span
          style={{
            padding: "2px 8px",
            borderRadius: "4px",
            height: "25px",
              display: "inline-block"
          }}
        >
              {label}
            </span>)}

      {isEditing ? (<div
          onClick={() => {
            if (editedLabel.trim() !== "") {
              onSave(editedLabel);
            } else {
              onDelete();
            }
            setIsEditing(false);
          }}
        >
          <Icon
            name="tabler:check"
            color="rgba(0, 117, 229, 1)"
            size="20px"
          />
        </div>) : null}
      <div onClick={handleDelete}>
        <Icon
          name="tabler:x"
          color="rgba(105, 111, 124, 1)"
          size="20px"
        />
      </div>
    </div>);
};

EditableChip.propTypes = {
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onValueChange: PropTypes.func,
  isAdding: PropTypes.bool,
}

EditableChip.defaultProps = {
  isAdding: false,
  onValueChange: (value) => {},
}