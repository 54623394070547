import PropTypes from "prop-types";

const AirplaneFilled = ({ color }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.035 3.43875C10.385 2.73875 11.0162 2 12 2C12.9838 2 13.615 2.74 13.965 3.43875C14.3262 4.1625 14.5 5.03875 14.5 5.75V10.3638L20.9625 13.595C21.2742 13.7506 21.5364 13.99 21.7197 14.2863C21.9029 14.5826 22 14.9241 22 15.2725V17C22 17.0907 21.9802 17.1804 21.942 17.2627C21.9038 17.345 21.8482 17.418 21.779 17.4766C21.7097 17.5353 21.6286 17.5781 21.5411 17.6022C21.4537 17.6263 21.362 17.6311 21.2725 17.6163L14.3888 16.4688L13.92 19.285L15.5675 20.9325C15.6551 21.0199 15.7148 21.1314 15.739 21.2528C15.7633 21.3741 15.7509 21.5 15.7035 21.6143C15.6561 21.7287 15.5758 21.8264 15.4728 21.895C15.3698 21.9637 15.2488 22.0002 15.125 22H8.875C8.75122 22.0002 8.63017 21.9637 8.52718 21.895C8.4242 21.8264 8.34392 21.7287 8.29652 21.6143C8.24912 21.5 8.23674 21.3741 8.26095 21.2528C8.28516 21.1314 8.34487 21.0199 8.4325 20.9325L10.0813 19.285L9.61125 16.4688L2.7275 17.6163C2.638 17.6311 2.54634 17.6263 2.45888 17.6022C2.37142 17.5781 2.29026 17.5353 2.22103 17.4766C2.1518 17.418 2.09617 17.345 2.058 17.2627C2.01983 17.1804 2.00004 17.0907 2 17V15.2725C2.00001 14.9241 2.09709 14.5826 2.28035 14.2863C2.4636 13.99 2.72579 13.7506 3.0375 13.595L9.5 10.3638V5.75C9.5 5.04 9.675 4.16125 10.035 3.43875Z" fill={color}/>
    </svg>
);

AirplaneFilled.propTypes = {
    color: PropTypes.string,
};

AirplaneFilled.defaultProps = {
    color: "white",
};
export default AirplaneFilled;
