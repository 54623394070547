import PropTypes from "prop-types";

const SchoolPlusFilled = ({ color }) => (
    <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M12.26 14.645C12.1 14.705 11.93 14.705 11.77 14.645L5.40002 12.205V15.835C5.40002 16.985 6.30002 17.915 7.50002 18.515C8.74002 19.125 10.36 19.455 12.03 19.455C13.7 19.455 15.32 19.125 16.55 18.515C17.75 17.915 18.65 16.985 18.65 15.835V12.195L12.26 14.645ZM22.26 8.24501L12.26 4.24501C12.11 4.18501 11.94 4.18501 11.79 4.24501L1.79002 8.24501C1.56002 8.33501 1.40002 8.56501 1.40002 8.82501V14.825C1.40002 15.165 1.68002 15.445 2.03002 15.445C2.38002 15.445 2.65002 15.165 2.65002 14.825V9.74501L5.40002 10.845L11.78 13.295C11.93 13.355 12.11 13.355 12.26 13.295L18.65 10.845L22.26 9.40501C22.5 9.30501 22.65 9.07501 22.65 8.82501C22.65 8.57501 22.5 8.33501 22.26 8.24501Z" fill={color}/>
        <path d="M25.62 21.7H23.24V19.32C23.24 19.32 23.22 19.24 23.21 19.2V19.15C23.21 19.15 23.2 19.13 23.19 19.11C23.19 19.08 23.17 19.06 23.16 19.04C23.14 19 23.11 18.96 23.08 18.92C23.06 18.9 23.05 18.88 23.03 18.86C22.99 18.83 22.95 18.8 22.9 18.78C22.88 18.77 22.86 18.76 22.84 18.75C22.77 18.72 22.7 18.7 22.62 18.7C22.27 18.7 22 18.98 22 19.32V21.7H19.62C19.27 21.7 19 21.98 19 22.32C19 22.49 19.07 22.65 19.18 22.76C19.22 22.8 19.26 22.82 19.31 22.85C19.33 22.86 19.35 22.89 19.38 22.9C19.45 22.93 19.54 22.95 19.62 22.95H22V25.33C22 25.68 22.28 25.95 22.62 25.95C22.96 25.95 23.24 25.67 23.24 25.33V22.95H25.62C25.97 22.95 26.24 22.67 26.24 22.33C26.24 21.99 25.96 21.71 25.62 21.71V21.7Z" fill={color}/>
        </svg>
);

SchoolPlusFilled.propTypes = {
        color: PropTypes.string,
};

SchoolPlusFilled.defaultProps = {
        color: "white",
};

export default SchoolPlusFilled;
