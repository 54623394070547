import _ from 'lodash';
import { columnsFromData } from '../../components/Table/utility';
import { CurrencyCell } from '../../components/Cells/CurrencyCell';
import { IntegerCell } from '../../components/Cells/IntegerCell';
import {DATE_NEW_EXPENSES_TYPE} from "../../constants";
import { MonthContext } from '../../context/MonthContext';
import { useContext } from 'react';

const columnOrder = [
  'rowNumber',
  'date',
  'type',
  'description',
  'kilometers',
  'kilometer_cost',
  'total_cost',
  'action',
];

const prepareTableKilometers = (props) => {
  const { trips, isReportConfirmed } = props;
  const columns = columnsFromData({ data: trips });
  const hiddenColumns = ['id', 'report'];
  const { month } = useContext(MonthContext);
  const dateSelected = new Date(month.format('YYYY'), month.format('MM') -1, 1);

  if(dateSelected < DATE_NEW_EXPENSES_TYPE){
    hiddenColumns.push('type');
  }

  if (isReportConfirmed) {
    hiddenColumns.push('action');
  }
  const kilometersColumn = _.find(columns, ['Header', 'kilometers']);
  if (kilometersColumn) {
    kilometersColumn.Cell = IntegerCell;
  }
  const costColumn = _.find(columns, ['Header', 'total_cost']);
  if (costColumn) {
    costColumn.Cell = CurrencyCell;
  }
  const kmCostColumn = _.find(columns, ['Header', 'kilometer_cost']);
  if (kmCostColumn) {
    kmCostColumn.Cell = CurrencyCell;
  }

  return {
    columns,
    columnOrder,
    hiddenColumns,
  };
};

export default prepareTableKilometers;
