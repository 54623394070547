import {PROJECT_GROUP_BY} from "../../constants";
import {StripedDataGrid} from "../../components/DataGrid/StripedDataGrid";
import CustomChip from "../../components/Chips/CustomChip";
import Icon from "../../components/Icon";
import {GridActionsCellItem, itIT} from "@mui/x-data-grid";
import PropTypes from "prop-types";

const ProjectsDataGrid = ({
  data,
  groupBy,
  deleteProjectById,
  onEditClick,
}) => {
  const allColumns = [
    {
      field: "customer",
      headerName: "Cliente",
      flex: 1.5,
    },
    {
      field: "label",
      headerName: "Progetto",
      flex: 2,
    },
    {
      field: "issues",
      headerName: "Issues",
      flex: 1.5,
      renderCell: (params) => <div style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
      }}>
        {params.value.map((issue) => issue.label).join(", ")}
      </div>
    },
    {
      field: "users",
      headerName: "Utenti",
      flex: 2,
    },
    {
      field: "active",
      headerName: "Stato",
      flex: 0.5,
      renderCell: (params) => (
        <CustomChip
          value={params.value ? "Attivo" : "Non Attivo"}
          bgColor={
            params.value ? "rgba(236, 248, 242, 1)" : "rgba(227, 229, 231, 1)"
          }
          textColor={
            params.value ? "rgba(15, 109, 3, 1)" : "rgba(105, 111, 124, 1)"
          }
        />
      ),
      minWidth: 100,
    },
    {
      field: "actions",
      headerName: "Azioni",
      flex: 0.5,
      renderCell: (params) => (
        <div className="grid-actions">
          <GridActionsCellItem
            key={`edit-${params.id}`}
            icon={
              <Icon
                name="tabler:pencil"
                iconColor="rgba(105, 111, 124, 1)"
                size="20px"
              />
            }
            label="Edit"
            className="textPrimary"
            onClick={() => {
              onEditClick(params.row);
            }}
            color="inherit"
          />
          <GridActionsCellItem
            key={`delete-${params.id}`}
            icon={
              <Icon
                name="tabler:trash"
                iconColor="rgba(105, 111, 124, 1)"
                size="20px"
              />
            }
            label="Delete"
            className="textPrimary"
            onClick={() => deleteProjectById(params.id)}
            color="inherit"
          />
        </div>
      ),
      minWidth: 100,
    },
  ];

  const getColumns = (params) => {
    let columns = [...allColumns];

    const fieldExclusions = {
      [PROJECT_GROUP_BY.CUSTOMER]: ["projectName", "issues", "isActive"],
      [PROJECT_GROUP_BY.PROJECT]: ["customerName"],
      [PROJECT_GROUP_BY.STATUS]: ["isActive"],
    };

    if (groupBy in fieldExclusions) {
      columns = columns.filter(
        (col) => !fieldExclusions[params.groupBy].includes(col.field)
      );
    }

    return columns;
  };

  const getRows = (params) => {
    let rows = JSON.parse(JSON.stringify(params.data));

    rows = rows.map((row) => ({
      ...row,
      users: row.users.map((user) => user.toUpperCase()),
    }));

    if (params.groupBy !== PROJECT_GROUP_BY.NONE) {
      rows = rows.reduce((acc, cur) => {
        let groupKey;
        let groupName;

        if (params.groupBy === PROJECT_GROUP_BY.CUSTOMER) {
          groupKey = cur.customerName;
          groupName = cur.customerName;
        } else if (params.groupBy === PROJECT_GROUP_BY.PROJECT) {
          groupKey = cur.projectName;
          groupName = cur.projectName;
        } else if (params.groupBy === PROJECT_GROUP_BY.STATUS) {
          groupKey = cur.isActive;
          groupName = cur.isActive ? "Active" : "Inactive";
        } else {
          return acc;
        }

        const existingItem = acc.find((i) => i.groupKey === groupKey);

        if (existingItem) {
          existingItem.users = [...new Set([...existingItem.users, ...cur.users])];
          existingItem.issues = [...new Set([...existingItem.issues, ...cur.issues])];
        } else {
          acc.push({
            groupKey,
            groupName,
            users: [...cur.users],
            issues: [...cur.issues],
            isActive: cur.isActive,
          });
        }

        return acc;
      }, []);
    }
    return rows;
  };

  const columns = getColumns({groupBy});
  const rows = getRows({
    data,
    groupBy,
  });

  return (
    <StripedDataGrid
      localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
      rows={rows}
      columns={columns}
      getRowId={(row) => row.id}
      pageSizeOptions={[25, 50, 100]}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 100,
          },
        },
      }}
      sx={{
        border: "none",
        borderRadius: "10px",
        backgroundColor: "white",
        height: 800,
      }}
      disableRowSelectionOnClick
    />
  );
};

ProjectsDataGrid.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  groupBy: PropTypes.oneOf(Object.values(PROJECT_GROUP_BY)).isRequired,
  deleteProjectById: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
};

export default ProjectsDataGrid;