import GoalItem from "./GoalItem";
import PropTypes from "prop-types";

const GoalGraphic = ({data}) => {
    const renderGoalItems = () =>
        data.goalTypes.map((goalType, index) => {
            const {
                name = '',
                percentage,
                currentYear = 0,
                prevYear = 0,
                achievedCurrYear,
                partiallyAchievedCurrYear,
                notAchievedCurrYear,
                achievedPrevYear,
                partiallyAchievedPrevYear,
                notAchievedPrevYear,
            } = goalType;

            const currentYearData = {
                achieved: achievedCurrYear,
                partiallyAchieved: partiallyAchievedCurrYear,
                notAchieved: notAchievedCurrYear,
            };

            const prevYearData = {
                achieved: achievedPrevYear,
                partiallyAchieved: partiallyAchievedPrevYear,
                notAchieved: notAchievedPrevYear,
            };

            return (
                <GoalItem
                    key={index}
                    name={name.toString()} // Explicit conversion
                    percentage={percentage}
                    maxValue={data.maxValue}
                    currentYearValue={currentYear}
                    previousYearValue={prevYear}
                    currentYearData={currentYearData}
                    prevYearData={prevYearData}
                />
            );
        });

    return <div className="goal-graphic">{renderGoalItems()}</div>;
};

// Define PropType structure
const GoalTypeShape = PropTypes.shape({
    name: PropTypes.string.isRequired,
    prevYear: PropTypes.number.isRequired,
    currentYear: PropTypes.number.isRequired,
    achievedCurrYear: PropTypes.number.isRequired,
    achievedPrevYear: PropTypes.number.isRequired,
    partiallyAchievedCurrYear: PropTypes.number.isRequired,
    partiallyAchievedPrevYear: PropTypes.number.isRequired,
    notAchievedCurrYear: PropTypes.number.isRequired,
    notAchievedPrevYear: PropTypes.number.isRequired,
    percentage: PropTypes.number.isRequired,
});

GoalGraphic.propTypes = {
    data: PropTypes.shape({
        maxValue: PropTypes.number.isRequired,
        goalTypes: PropTypes.arrayOf(GoalTypeShape).isRequired,
    }).isRequired,
};

export default GoalGraphic;