import PropTypes from "prop-types";
import Icon from "../Icon";

const CustomChip = ({value, iconName, iconSize, iconColor, bgColor, textColor}) =>
    <div
        style={{
            fontSize: '14px',
            backgroundColor: bgColor,
            borderRadius: '100px',
            padding: '3px 10px',
            color: textColor,
            fontWeight: 400,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
            gap: "4px"
        }}
    >
        {iconName && <Icon name={iconName} size={iconSize} color={iconColor}/>}
        {value}
    </div>


CustomChip.propTypes = {
    value: PropTypes.string.isRequired,
    iconName: PropTypes.string,
    iconSize: PropTypes.string,
    iconColor: PropTypes.string,
    bgColor: PropTypes.string,
    textColor: PropTypes.string
}

CustomChip.defaultProps = {
    iconName: '', iconSize: '1em', iconColor: '', bgColor: '#E5F3FF', textColor: '#13315D'
}

export default CustomChip;