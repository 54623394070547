import { Button } from 'react-bootstrap';
import _ from 'lodash';
import { useState } from 'react';
import PropTypes from 'prop-types';

import RowDetail from './rowDetailPanelTotal';
import { currencyFormatter, decimalFormatter } from '../Table/utility';

const PanelTotal = (props) => {
  const {
    report,
    summaryData,
    handleShowModalConfirm,
  } = props;

  const isReportConfirmed = report.confirmed || !report.id;
  const getCost = (key) => _.get(summaryData, key, 0);

  const getHours = () => {
    const { format } = decimalFormatter;
    const hoursLogged = _.get(summaryData, 'total_time_spent', 0);
    const hoursTodayExpected = _.get(summaryData, 'total_today_time', 0);
    const hoursMonthExpected = _.get(summaryData, 'total_time', 0);
    const msg = `${format(hoursLogged)} di ${format(hoursTodayExpected)}`;
    const isFinal = hoursTodayExpected === hoursMonthExpected;
    return isFinal ? (
      msg
    ) : (
      <>
        {msg}
        <br />
        [Tot. ore mese {format(hoursMonthExpected)}]
      </>
    );
  };

  const hoursStatus = () => {
    const hoursLogged = _.get(summaryData, 'total_time_spent', 0);
    const hoursExpected = _.get(summaryData, 'total_today_time', 0);
    return hoursLogged !== hoursExpected;
  };

  const renderActions = () => {
    if (!isReportConfirmed) {
      return (
        <Button
          className="btn btn-primary"
          onClick={handleShowModalConfirm}
          style={{ width: '100%' }}
        >
          Chiudi mese
        </Button>
      );
    }
    return (
      <div className="expenses-report-status">
        <div>
          <span className="icon-check" />
          <span>CONFIRMED</span>
        </div>
        {report.notes && (
          <div className="expenses-report-status-note">
            <span>Notes: {report.notes}</span>
          </div>
        )}
      </div>
    );
  };

  const [closed, setClosed] = useState(false);

  return (
    <div className={`sidebar  ${closed ? 'closed' : ''}`}>
      <div className="sidebar__header">
        <div className="icon-bag" />
        Riepilogo Rimborso
      </div>
      <div className="sidebar__body">
        {!closed && (
          <>
            <RowDetail
              label="Ore loggate"
              value={getHours()}
              valueExtraClasses={hoursStatus() ? 'error' : null}              
              icon="icon-time"
            />
            <RowDetail
              label="Rimborso Km"
              value={currencyFormatter.format(getCost('trips_cost'))}
              icon="icon-car"
            />
            <RowDetail
              label="Rimborso spese"
              value={currencyFormatter.format(getCost('expenses_cost'))}
              icon="icon-bag"
            />
            <RowDetail
              label="Anticipo"
              value={currencyFormatter.format(getCost('cash_advances'))}
              icon="icon-card"
            />
            <hr className="divider" />
            <div className="total__details">
              <div className="total__details__label">Totale da liquidare</div>
              <div className="total__details__value">
                {currencyFormatter.format(getCost('total_due'))}
              </div>
            </div>
            {renderActions()}
          </>
        )}
      </div>
      <div className="sidebar__footer">
        <button
          className="btn btn--light"
          type="button"
          onClick={() => setClosed(!closed)}
        >
          {closed ? (
            <div className="icon-arrow-double-left" />
          ) : (
            <div className="icon-arrow-double-right" />
          )}
          Collapse
        </button>
      </div>
    </div>
  );
};

PanelTotal.defaultProps = {
  report: {},
  summaryData: {},
  handleShowModalConfirm: () => {},
};

PanelTotal.propTypes = {
  report: PropTypes.instanceOf(Object),
  summaryData: PropTypes.instanceOf(Object),
  handleShowModalConfirm: PropTypes.func,
};

export default PanelTotal;
